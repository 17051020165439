<script setup>
import { onMounted, ref } from "vue";
import Swiper from "swiper";
import EffectMaterial from "@/vendor/effect-material.esm.js";
import "swiper/css";
import "@/vendor/effect-material.scss";

const el = ref(null);
const swiper = ref(null);

onMounted(() => {
    swiper.value = new Swiper(el.value, {
        modules: [EffectMaterial],
        effect: "material",
        materialEffect: {
            slideSplitRatio: 1 / 2,
        },
        loop: true,
        loopAdditionalSlides: 4,
        loopAddBlankSlides: true,
        slidesPerView: 1,
        breakpoints: {
            1024: {
                slidesPerView: 3,
            },
        },
        grabCursor: true,
        spaceBetween: 10,
        speed: 300,
    });
});

const slideNext = () => {
    if (!swiper.value) {
        return;
    }

    swiper.value.slideNext();
};
</script>

<template>
    <div class="swiper" ref="el">
        <div class="swiper-wrapper">
            <slot />
        </div>
    </div>
    <div class="case-slider__navigation">
        <span class="case-slider__navigation-button" @click="slideNext">
            <slot name="navigation-icon" />
        </span>
    </div>
</template>
