import "./scss/global.scss";
import "swiper/css";
import { createApp } from "vue";
import { register } from "swiper/element/bundle";
import { ExpandTransition, FadeTransition } from "@bam/transitions";
import { OnClickOutside, UseMouse } from "@vueuse/components";
import MiniMasonry from "@/vendor/minimasonry.js";

// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
    import.meta.hot.accept();
}

function pgshow(e) {
    var elId = window.location.hash;
    if (elId.length > 1) {
        const el = document.getElementById(elId.substr(1));
        if (el) {
            el.scrollIntoView({
                behavior: "smooth",
                block: "start",
                inline: "nearest",
            });
        }
    }
}
window.addEventListener("pageshow", pgshow);

document.addEventListener("DOMContentLoaded", () => {
    const images = document.querySelectorAll(".stage-animation__image");
    let loadedImages = 0;

    function decodeImage(image) {
        image
            .decode()
            .then(() => {
                loadedImages++;
                if (loadedImages === images.length) {
                    document
                        .querySelector(".stage-animation")
                        .classList.add("stage-animation--running");
                }
            })
            .catch((encodingError) => {
                console.log(encodingError);
            });
    }

    images.forEach((image) => {
        if (image.complete) {
            // If the image is already loaded (cached), decode it directly
            decodeImage(image);
        } else {
            // If the image is not yet loaded, decode it when it finishes loading
            image.onload = () => decodeImage(image);
        }
    });
});

const app = createApp({
    data: () => {
        return {
            minimasonry: null,
            // globalSwiperModules: [Pagination, Parallax],
        };
    },

    mounted() {
        register();

        const hasMasonry = document.querySelector(".masonry-grid-dyn");
        if (hasMasonry) {
            this.minimasonry = new MiniMasonry({
                baseWidth: 320,
                container: ".masonry-grid-dyn",
                gutter: 20,
                surroundingGutter: false,
                wedge: true,
                ultimateGutter: 20,
            });

            window.addEventListener("resize", this.updateBaseWidth);
            this.updateBaseWidth();
        }
    },

    methods: {
        updateBaseWidth() {
            if (window.innerWidth < 680) {
                this.minimasonry.conf.baseWidth = 320;
            } else if (window.innerWidth < 1440) {
                this.minimasonry.conf.baseWidth = 300;
            } else {
                this.minimasonry.conf.baseWidth = 500;
            }

            this.$nextTick(() => {
                this.minimasonry.layout();
            });
        },
    },

    onBeforeUnmount() {
        const hasMasonry = document.querySelector(".masonry-grid-dyn");
        if (hasMasonry) {
            window.removeEventListener("resize", this.updateBaseWidth);
        }
    },
});

const components = import.meta.glob("./components/**/*.vue", { eager: true });
Object.entries(components).forEach(([path, definition]) => {
    const componentName = path
        .split("/")
        .pop()
        .replace(/\.\w+$/, "");
    app.component(componentName, definition.default);
});

app.component("expand-transition", ExpandTransition);
app.component("fade-transition", FadeTransition);
app.component("use-mouse", UseMouse);
app.component("on-click-outside", OnClickOutside);

app.mount("#app");
