<script setup>
import { onMounted, onBeforeUnmount, ref } from "vue";
import Swiper from "swiper";
import "swiper/css";

const el = ref(null);
const swiper = ref(null);
const isSlidable = ref(false);

const checkIsSlidable = () => {
    isSlidable.value = !swiper.value.isLocked;
};

onMounted(() => {
    swiper.value = new Swiper(el.value, {
        rewind: true,
        // loopAddBlankSlides: true,
        // loopAdditionalSlides: 10,
        slidesPerView: "auto",
        spaceBetween: 10,
    });

    checkIsSlidable();

    window.addEventListener("resize", checkIsSlidable);
});

const slideNext = () => {
    if (!swiper.value) {
        return;
    }

    swiper.value.slideNext();
};

onBeforeUnmount(() => {
    window.removeEventListener("resize", checkIsSlidable);
});
</script>

<template>
    <div class="swiper" ref="el" :class="{ 'swiper--slidable': isSlidable }">
        <div class="swiper-wrapper">
            <slot />
        </div>
    </div>
    <div class="tile-slider__navigation" v-if="isSlidable">
        <span class="tile-slider__navigation-button" @click="slideNext">
            <slot name="navigation-icon" />
        </span>
    </div>
</template>
